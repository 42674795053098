/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useState, useContext, useEffect, useRef } from 'react';
import logo from 'Assets/logo_round.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AppContext, AuthContext } from 'Context';
import Switch from '@mui/material/Switch';
import { FormGroup, FormControlLabel, Checkbox, Box } from '@material-ui/core';
import AddDotLoader from 'Pages/private/videos/AddDotLoader';
import { endpoints, baseUrl } from 'Api';
import axios from 'axios';
import styles from './index.module.css';
import Checkout from '../../checkoutStripe';
import Promolist from './Promolist';

function PriceSectionLayout({
  selectedPlan,
  setModal,
  handleCheckboxChange,
  checked,
  planName,
  planPrice,
  planId,
  availablePlans,
  allPlans,
  intervalInDays,
  navigation,
  promoCode,
  promoErrMsg,
  setPromoErrMsg,
  setPromoCodeNew,
  promoCodeNew,
  promoLoading,
  setPromoLoading
}) {
  const { affiliateCode, setAffiliatedCode, setIsTrail, isTrail, trialVal, apiResponse, subscriptionDetails, selectPlan, setAffliatePlanId, setCurrentSelectedPlan } = useContext(AppContext);
  const debounceRef = useRef(null); // Ref for debounce timer
  const axiosCancelToken = useRef(null);
  const [manager, setManager] = useState(null);
  const [active, setActive] = useState(false);
  const [promoActive, setPromoActive] = useState(false);
  const [promoResponse, setPromoResponse] = useState(null);
  const [discountedPrice, setDiscountedPrice] = useState('');
  const [calculatedDiscount, setCalculatedDiscount] = useState('');
  const { getToken } = useContext(AuthContext);
  const location = useLocation();
  const calculateDiscountedPrice = (price) => {
    const priceInDollar = price / 100;
    const discountedPrice = planPrice?.amount ? (planPrice?.amount - priceInDollar) : (planPrice - priceInDollar);
    const formattedDiscountedPrice = discountedPrice.toFixed(2);
    setCalculatedDiscount(priceInDollar?.toFixed(2));
    setDiscountedPrice(formattedDiscountedPrice);
  };
  const [isPromoError, setIsPromoError] = useState(false);
  const [isAffliateError, setIsAffliateError] = useState(false);
  const handleAffiliatedCodeChange = async (event) => {
    const typedData = event.target.value;
    setAffiliatedCode(typedData);
    setManager('');
    if (event.target.value?.length > 0) {
      setIsAffliateError(true);
    } else {
      setIsAffliateError(false);
    }
    if (event.target.value?.length > 4) {
      setActive(true);
      try {
        axios({
          url: baseUrl + endpoints.getAffiliateName,
          method: 'POST',
          data: { code: typedData, token: getToken() },
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((response) => {
          setManager(`${response.data?.data?.firstname} ${response.data?.data?.lastname}`);
          setIsAffliateError(false);
        });
      } catch (error) {
        console.error('Error:', error);
        setIsAffliateError(true);
      }
    } else if (event.target.value < 5) {
      setActive(false);
    }
  };

  const handlePromoCodeChange = (e) => {
    const newPromoCode = e.target.value?.toUpperCase();
    setPromoCodeNew(newPromoCode);

    if (newPromoCode?.length >= 6) {
      setPromoActive(true);
      setPromoLoading(true);
    }
    if (newPromoCode?.length > 0) {
      setIsPromoError(true);
    } else {
      setIsPromoError(false);
      setPromoActive(false);
    }
    // Clear previous debounce timer
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    // Cancel any ongoing API request
    if (axiosCancelToken.current) {
      axiosCancelToken.current.cancel('Operation canceled due to new request.');
    }

    // Create a new CancelToken
    axiosCancelToken.current = axios.CancelToken.source();

    // Debounce logic for API call
    debounceRef.current = setTimeout(async () => {
      try {
        const response = await axios({
          url: `${baseUrl}/getPromoAmount`,
          method: 'POST',
          data: { token: getToken(), promo_code: newPromoCode, plan_id: planId },
          headers: { 'Content-Type': 'application/json' },
          cancelToken: axiosCancelToken.current.token, // Attach CancelToken
        });

        setPromoResponse(response.data);
        calculateDiscountedPrice(response.data?.data?.amount);
        setPromoErrMsg('');
        setIsPromoError(false);
      } catch (error) {
        if (!axios.isCancel(error)) {
          setPromoErrMsg(error?.response?.data?.message || 'Something went wrong');
          setPromoResponse(null);
          setDiscountedPrice('');
          if (newPromoCode?.length === 0) {
            setIsPromoError(false);
          } else {
            setIsPromoError(true);
          }
        }
      } finally {
        setPromoLoading(false);
      }
    }, 500); // Debounce time: 1 second
  };

  useEffect(() => {
    localStorage.removeItem('promocode');
    localStorage.removeItem('affiliate');
    // localStorage.removeItem('planId');
    setAffiliatedCode(localStorage.getItem('code'));
    return () => {
      setIsTrail('0'); // This will run when the component unmounts
    };
  }, []);
  const fetchPromoAmount = async () => {
    const tokenx = getToken();
    try {
      const response = await axios({
        url: `${baseUrl}/getPromoAmount`,
        method: 'POST',
        data: { token: tokenx, promo_code: apiResponse?.promo, plan_id: selectedPlan },
        headers: { 'Content-Type': 'application/json' },
        cancelToken: axiosCancelToken.current.token,
      });
      setPromoResponse(response.data);
      calculateDiscountedPrice(response.data?.data?.amount);
      setPromoErrMsg('');
      setPromoLoading(false);
      setPromoActive(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        setPromoLoading(false);
        setPromoActive(false);
      } else {
        throw error;
      }
    }
  };
  useEffect(() => {
    if (apiResponse?.promo) {
      setPromoActive(true);
      setPromoLoading(true);
      axiosCancelToken.current = axios.CancelToken.source(); // Ensure CancelToken is initialized
      fetchPromoAmount().catch((error) => {
        console.error('Error fetching promo amount:', error);
      });
    }
  }, [apiResponse?.promo]);
  const [isChecked, setIsChecked] = useState(planId === '14' || allPlans?.id === '14');
  const handleSwitchToggle = async () => {
    setIsChecked((prev) => !prev); // Toggle the switch state
    const newPlanId = planId === '11' ? '14' : '11'; // Determine the new plan ID
    selectPlan(newPlanId); // Update the selected plan
    setAffliatePlanId(newPlanId);
    setCurrentSelectedPlan(availablePlans?.find((plan) => plan?.id === newPlanId));
    try {
      setPromoLoading(true);
      setPromoErrMsg('');

      // Cancel any previous promo API request
      if (axiosCancelToken.current) {
        axiosCancelToken.current.cancel('Operation canceled due to new request.');
      }
      axiosCancelToken.current = axios.CancelToken.source();

      // Call the promo API with the new plan ID
      const response = await axios({
        url: `${baseUrl}/getPromoAmount`,
        method: 'POST',
        data: { token: getToken(), promo_code: promoCodeNew, plan_id: newPlanId }, // Use the new plan ID
        headers: { 'Content-Type': 'application/json' },
        cancelToken: axiosCancelToken.current.token,
      });

      if (response.data?.data?.amount) {
        setPromoResponse(response.data);
        calculateDiscountedPrice(response.data?.data?.amount);
        setIsPromoError(false);
      } else {
        setPromoErrMsg('Invalid promo code.');
        setIsPromoError(true);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        setPromoErrMsg(error?.response?.data?.message || 'Something went wrong');
        setIsPromoError(true);
      }
    } finally {
      setPromoLoading(false);
    }
  };
const affiliateInputRef = useRef(null);
  const promoInputRef = useRef(null);
  return (
    <>
      <div className={styles.mainDivx}>
        {subscriptionDetails?.paymentMethods?.length > 0 && (
            <FormGroup>
            <FormControlLabel
                          control={(
                            <Checkbox
                              style={{
                                color: '#1172D2',
                              }}
                              checked={checked}
                              onChange={handleCheckboxChange}
                            />
                          )}
                          label={navigation === '/settings/manage-users' ? 'Use your account information' : 'Use your default card'}
            />
            </FormGroup>
        )}
        {/* {availablePlans?.length > 1 && (
        <Switch
            checked={isChecked} // Bind switch state
            onChange={handleSwitchToggle}
        />
        )} */}
        {isTrail === '1'
          && (
            <p className={styles.title}>
              Projector
              {' '}
                {selectedPlan?.interval_in_days === '365'
                  ? 'Annual'
                  : selectedPlan?.interval_in_days === '30'
                  ? 'Monthly'
                  : selectedPlan?.interval_in_days === '7'
                  ? 'Weekly'
                  : selectedPlan?.interval_in_days === '36500'
                  ? 'Lifetime'
                  : 'Days'}
              {' '}
              Subscription
              <br />
              You will be charged in
              {' '}
              {selectedPlan?.total_trial_days}
              {' '}
              {selectedPlan?.total_trial_days === '1' ? 'day' : 'days'}
            </p>
          )}
        <div id="captureSection">
        <p className={styles.subTitle}>
  {selectedPlan?.interval_in_days === '36500' ? (
    `$${planPrice?.amount ? planPrice?.amount : planPrice} for Lifetime`
  ) : (
    `$${planPrice?.amount ? planPrice?.amount : planPrice}/${
      selectedPlan?.interval_in_days === '365'
        ? 'Year'
        : selectedPlan?.interval_in_days === '30'
        ? 'Month'
        : selectedPlan?.interval_in_days === '7'
        ? 'Week'
        : 'Day'
    }`
  )}
        </p>

        <div className={styles.logoDiv}>
          <div className={styles.logoDivImg}>
            <img width="50px" src={logo} />
          </div>
          <div className={styles.projectorTitleDiv}>
            <p className={styles.projectorTitle}>Projector</p>
            <p className={styles.projectorSubTitle}>
  {selectedPlan?.interval_in_days === '36500'
    ? 'Billed for a Lifetime'
    : `Billed ${
        selectedPlan?.interval_in_days === '365'
          ? 'Annually'
          : selectedPlan?.interval_in_days === '30'
          ? 'Monthly'
          : selectedPlan?.interval_in_days === '7'
          ? 'Weekly'
          : 'Daily'
      }`}
            </p>

            <p className={styles.subTotal}>Subtotal</p>
          </div>
          <div className={styles.projectorPriceDiv}>
            <p className={styles.price}>{`$${planPrice?.amount ? planPrice?.amount?.toFixed(2) : planPrice?.toFixed(2)}`}</p>
            <p style={{ marginTop: '23px' }} className={styles.price}>{`$${planPrice?.amount ? planPrice?.amount?.toFixed(2) : planPrice?.toFixed(2)}`}</p>
          </div>
        </div>
        {localStorage.getItem('code') ? (
          <p>{localStorage.getItem('code')}</p>
        ) : (
          <>
          <input
              ref={affiliateInputRef}
              className={styles.affiliatedCode}
              value={affiliateCode || promoCode}
              onChange={handleAffiliatedCodeChange}
              type="number"
              disabled={promoCode}
              placeholder={promoCode || 'Add Affiliate code'}
          />
            {!apiResponse?.promo
              ? (
                    <input
                ref={promoInputRef}
                className={styles.promotedCode}
                value={promoCodeNew}
                onChange={handlePromoCodeChange}
                placeholder="Add Promo code"
                maxLength={14}
                minLength={1}
                    />
)
              : (
<div className={styles.affiliatedCodex} style={{ background: '#EFEFEF4D' }}>
              {apiResponse?.promo}
</div>
)}

          </>
        )}
        {promoCodeNew?.length > 0 && promoCodeNew?.length < 6 && (
          <p className={styles.promoCode} style={{ color: '#bb2124', paddingBottom: '0' }}>
            *Promo code requires at least 6 characters!
          </p>
        )}
        {promoActive && (
          promoLoading ? (
            <p className={styles.promoCode}><AddDotLoader text="Loading" /></p>
          ) : !promoErrMsg ? (
            <p className={styles.promoCode} style={{ color: '#22bb33', paddingBottom: '0' }}>{`${promoResponse?.data?.name} Applied!`}</p>
          ) : (
            <p className={styles.promoCode} style={{ color: '#bb2124', paddingTop: '12px', paddingBottom: '0' }}>
{promoErrMsg}
            </p>
          )
        )}
        {promoResponse?.data?.data?.map((promo, index) => (
    <Promolist
      key={index}
      i={index}
      discountedPrice={promo?.amount}
      promoCodeNew={promoCodeNew}
      regularPrice={planPrice?.amount ? planPrice?.amount?.toFixed(2) : planPrice?.toFixed(2)}
    />
  ))}
        {manager
          ? (
            <p className={styles.mangerx}>
              Manger Name:
              {' '}
              {manager}
            </p>
          )
          : active === true
            ? (<p className={styles.invalid}>Invalid Code</p>)
            : null}
        {isTrail === '1' && (
          <div>
          <div className={styles.regularDate}>
            <p className={styles.projectorTitle} style={{ marginBottom: '0' }}>Regular Rate </p>
            <p className={styles.totalPrice} style={{ textDecoration: 'line-through', marginBottom: '0' }}>{`$${planPrice?.amount ? planPrice?.amount?.toFixed(2) : planPrice?.toFixed(2)}`}</p>
          </div>
{discountedPrice && (
          <div className={styles.regularDate}>
            <p className={styles.promoTitle}>
{' '}
{promoResponse?.data?.name}
            </p>
            <p className={styles.promoPrice}>
{' '}
-
{`$${calculatedDiscount}`}
            </p>
          </div>
)}
          </div>
        )}
        {(isTrail !== '1' && discountedPrice !== '') && (
          <div>
          <div className={styles.regularDate}>
            <p className={styles.projectorTitle}>Regular Rate </p>
            <p className={styles.totalPrice} style={{ textDecoration: discountedPrice !== '' && 'line-through', marginBottom: '0' }}>{`$${planPrice?.amount ? planPrice?.amount?.toFixed(2) : planPrice?.toFixed(2)}`}</p>
          </div>
{discountedPrice && (
  <div className={styles.regularDate}>
    <p className={styles.promoTitle}>
{' '}
{promoResponse?.data?.name}
    </p>
    <p className={styles.promoPrice}>
{' '}
-
{`$${calculatedDiscount}`}
    </p>
  </div>
)}
          </div>
        )}
        <div className={styles.total}>
          <p style={{ marginTop: '10px' }} className={styles.projectorTitle}>Total Due Today </p>
          {isTrail === '1' || trialVal === '1' ? (<p style={{ marginTop: '10px' }} className={styles.totalPrice}>$0.00</p>)
            : (
              <p style={{ marginTop: '10px' }} className={styles.totalPrice}>{`$${discountedPrice !== '' ? discountedPrice : planPrice?.amount?.toFixed(2) || planPrice?.toFixed(2)}`}</p>
            )}
        </div>

        {(isTrail === '1' || trialVal === '1') && (
        <div className={styles.total}>
          <p className={styles.projectorTitle}>
          You will be charged in
{' '}
{selectedPlan?.total_trial_days}
{' '}
{selectedPlan?.total_trial_days === '1' ? 'day' : 'days'}
          </p>
          <p className={styles.totalPrice}>{`$${discountedPrice !== '' ? discountedPrice : planPrice?.amount?.toFixed(2) || planPrice?.toFixed(2)}`}</p>
        </div>
          )}
        </div>
        <div className={styles.flexmon}>
          <Checkout
          planId={planId}
          affiliateInputRef={affiliateInputRef}
          promoInputRef={promoInputRef}
          isAffliateError={isAffliateError}
          isPromoError={isPromoError}
          navigation={navigation}
          affiliateCode={affiliateCode}
          checked={checked}
          promoCode={promoCode}
          promoCodeNew={promoCodeNew}
          amount={discountedPrice !== '' ? discountedPrice * 100 : planPrice?.amount * 100 || planPrice * 100}
          />
          {location.pathname === '/pay-by-manger' && (
      <button
        style={{ display: checked === true && 'none' }}
        className={styles.continueButton}
        onClick={() => setModal(true)}
        // disabled={isFormFilled}
      >
                    Let customer pay
      </button>
          )}
        </div>

      </div>
    </>
  );
}

export default PriceSectionLayout;
