import React, { useContext, useState, useEffect } from 'react';
import { useStripe, CardElement } from '@stripe/react-stripe-js';
import { useNavigate, useLocation } from 'react-router-dom';
import { axios, dataFetcher, endpoints, baseUrl } from 'Api';
// eslint-disable-next-line
import html2canvas from 'html2canvas';
import { loadStripe } from '@stripe/stripe-js';
import { AppContext, AuthContext } from 'Context';
import styles from './components/page-layout/index.module.css';
import stripeCredentials from '../../../../../Api/stripeCredentials.json';

const Checkout = ({
  planId,
  affiliateInputRef,
  promoInputRef,
  affiliateCode,
  promoCode,
  amount,
  promoCodeNew,
  checked,
  isPromoError,
  isAffliateError
}) => {
  const { userDetails, setisFromAffl, isFromAffl, setAppSnackbar, bigUserData, expressCheckout, isTrail, apiCALL, expressloading, setExpressLoading } = useContext(AppContext);
  const { getToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const stripePromise = loadStripe(stripeCredentials.STRIPE_PUBLIC_KEY);
  const userData = apiCALL?.data?.userDetails[0] || bigUserData;
  const userId = userData?.id || userData?.userId || localStorage.getItem('temp_user_id');
    const [capturedImage, setCapturedImage] = useState(null);
  useEffect(() => {
    if (location.pathname === '/pay-by-manger') {
      setisFromAffl(true);
      localStorage.setItem('affliate_userId', userId);
    } else {
      setisFromAffl(false);
      localStorage.removeItem('affliate_userId');
    }
  }, []);

  const captureAndSend = async () => {
    const element = document.getElementById('captureSection');
    if (!element) return console.error('Element not found');

    // Store original display styles
    const affiliateOriginalDisplay = affiliateInputRef.current?.style.display || '';
    const promoOriginalDisplay = promoInputRef.current?.style.display || '';

    // Hide empty input fields before capturing
    if (affiliateInputRef.current && !affiliateInputRef.current.value.trim()) {
      // eslint-disable-next-line
        affiliateInputRef.current.style.display = 'none';
    }
    if (promoInputRef.current && !promoInputRef.current.value.trim()) {
      // eslint-disable-next-line
        promoInputRef.current.style.display = 'none';
    }

    // Capture the canvas
    const canvas = await html2canvas(element);
    const imageBase64 = canvas.toDataURL('image/png');

    // // Restore the original display styles after capturing
    // if (affiliateInputRef.current) {
    //   // eslint-disable-next-line
    //     affiliateInputRef.current.style.display = affiliateOriginalDisplay;
    // }
    // if (promoInputRef.current) {
    //   // eslint-disable-next-line
    //     promoInputRef.current.style.display = promoOriginalDisplay;
    // }

    return imageBase64;
};

const handleCheckout = async (imageBase64) => {
  try {
      setExpressLoading(true);

      localStorage.setItem('affiliate', affiliateCode ?? promoCode);
      localStorage.setItem('promocode', promoCodeNew);
      localStorage.setItem('planId', planId);

      const body = new FormData();
      body.append('amount', amount);

      const stripe = await stripePromise;

      const response = await fetch(`${baseUrl}/checkout/create`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              token: getToken(),
              promo: localStorage.getItem('promocode'),
              trial: isTrail === '1',
              plan_id: planId,
              url: window.location.origin,
              image: imageBase64 // Pass Base64 instead of Blob
          })
      });

      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const { id } = await response.json();

      if (id) {
          const { error } = await stripe.redirectToCheckout({ sessionId: id });
          if (error) {
              throw new Error(error.message);
          }
      }
  } catch (error) {
    console.error('Checkout failed:', error);
    setExpressLoading(false);
    window.location.href = '/plans';
  }
};

  const handleCaptureAndCheckout = async () => {
    const imageBase64 = await captureAndSend(); // Capture and convert to Base64
    if (imageBase64) {
        await handleCheckout(imageBase64); // Send it
    }
};

  const expressOrStripe = () => {
    setExpressLoading(true);
    if (checked) {
      if (location.pathname === '/plans') {
        localStorage.setItem('affiliate', affiliateCode ?? promoCode);
      } else {
        localStorage.setItem('affiliate', promoCode);
      }
      localStorage.setItem('promocode', promoCodeNew);
      localStorage.setItem('managerCard', '1');
      setTimeout(() => {
        expressCheckout();
      }, 100);
    } else {
      localStorage.setItem('managerCard', '0');
      handleCaptureAndCheckout();
    }
  };
  return (
    <div>
      <button
                  className={styles.continueButton}
                  disabled={expressloading || isPromoError || isAffliateError}
                  onClick={expressOrStripe}
      >
                  {expressloading === true
                    ? (
                      <svg className="loading-circle_mini" width="20" height="20" viewBox="0 0 50 50">
                        <circle className="circle" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
                      </svg>
                    ) : 'Proceed to checkout'}
      </button>
    </div>
  );
};

export default Checkout;
