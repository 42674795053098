import { CircularProgress as MuiCircularProgress, withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Zoom,
  DialogContentText,
  DialogTitle,
  Button
} from '@material-ui/core';
import { axios, baseUrl, dataFetcher, endpoints, firebaseCredentials } from 'Api';
import { getAllActivePlans, addVideo } from 'Api/apiFunctions';
import { ALLOWED_UPLOADS_COUNT, PLANS } from 'Commons';
import { Snackbar } from 'Components';
import { ToastContainer, toast } from 'react-toastify';
import noPhotoAvailable from 'Assets/no-image-available.png';
import 'react-toastify/dist/ReactToastify.css';
import Referral from 'Pages/private/Referral';
import { AppContext, AuthContext } from 'Context';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { saveUploadInfo, clearAllDummys } from 'store/store';
import { initializeApp } from 'firebase/app';
import { ref, getDatabase, off, onValue, update } from 'firebase/database';
import {
  Access,
  AccountSettings,
  AlbumDetails,
  AlbumPlayer,
  BillingSettings,
  ConnectedAccountSettings,
  ContentLayout,
  ContentLayoutDashboard,
  ContentLayoutDetails,
  Dashboard,
  EmailVerification,
  EmailVerificationLink,
  ForgotPassword,
  GettingStarted,
  Groups,
  LandingPageExternal,
  ManageDevice,
  ManagePaymentMethods,
  MobileVerification,
  NotificationSettings,
  Privacy,
  PrivacySettings,
  Profile,
  PublicPlans,
  SearchPage,
  SignIn,
  SignUp,
  SubscriberAgreementPage,
  SubscriberAgreementSettings,
  Successors,
  TermsAndConditionSettings,
  TermsAndConditions,
  TermsAndServices,
  VerifyCode,
  VideoDetails,
  VideoPlayer,
  Viewers,
  Watchlist,
  YourVideos
} from 'Pages';
import { InnerPage } from 'Pages/private/innerpage';
import { NewMobileVerification } from 'Pages/public/authentication/newMobile-verification';
import { NewSignUP } from 'Pages/public/authentication/newSign-Up';
import { NewSignIn } from 'Pages/public/authentication/newSign-in';
import { Welcome } from 'Pages/public/authentication/Trail/Welcome';
import { NewVerifyCode } from 'Pages/public/authentication/newVerification-code';
import { Pathname } from 'Routes';
import 'Styles/site-global.css';
import './Pages/public/landing-external2/App.css';
import detectBrowserLanguage from 'detect-browser-language';
import useMyAxios from 'hooks/useMyAxios';
import { useCallback, useEffect, useState } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import useSWR from 'swr';
import MangerPaymentForUser from 'Pages/userManagement/MangerPaymentForUser';
import { PlanForAffliate } from 'Pages/public/landing/plans/PlanForAffliate';
import { UserMangementSettings } from 'Pages/userManagement/UserMangementSettings';
import CreateUser from 'CreateNewUser/CreateUser';
import ListingModal from 'Components/ListingModal';
import DropMe from 'Components/DropMe/DropMe';
import SuccessPopUp from 'Components/VideoPopup/SuccessPopUp';
import { navigateTo } from './navigationHelper';
import { UploadModal } from './Components/upload-modal/index';
import SubscriberAgreementDarkMode from './Pages/public/SubscriberAgreementDarkMode';
import DownArrow from './Assets/arrowdown.png';
import UpArrow from './Assets/arrowup.png';
import styles from './app.module.css';

const CircularProgress = withStyles({
  circle: {
    color: '#fff !important'
  }
})(MuiCircularProgress);

const defaultAppSnackbar = { isVisible: false, type: undefined, message: '', duration: 1500 };
const appSnackbarAutoHideDuration = 1500;
const getToken = () => localStorage.getItem('token');
const setToken = (token) => { localStorage.setItem('token', token); };
const removeToken = () => { localStorage.removeItem('token'); };
const setEmailVerified = (isVerified) => { localStorage.setItem('isVerified', isVerified); };
const getEmailVerified = () => localStorage.getItem('isVerified');
const removeEmailVerified = () => { localStorage.removeItem('isVerified'); };
const RouteSwitch = ({ isNewUser, userIsSignedIn, isInActiveUser }) => {
  const currentLocation = useLocation();
  useEffect(() => { window.scrollTo(0, 0); }, [currentLocation.pathname]);
  return (
    <Routes>
      {userIsSignedIn
        ? (
          isInActiveUser ? (
            <>
              <Route path={Pathname.access} element={<Access />} />
              <Route path={Pathname.dashboard} element={<Dashboard />} />
              <Route path={Pathname.settings.basePath} element={<BillingSettings />}>
                <Route index element={<BillingSettings />} />
                {/* <Route path="manage-payment-methods" element={<ManagePaymentMethods />} /> */}
                <Route path="privacy" element={<PrivacySettings />} />
                <Route path="billings" element={<BillingSettings />} />
                <Route path="termsAndConditions" element={<TermsAndConditionSettings />} />
                <Route path="subscriberAgreement" element={<SubscriberAgreementSettings />} />
              </Route>
              <Route path={Pathname.settings.basePath}>
                <Route index element={<AccountSettings />} />
                <Route path="manage-payment-methods" element={<ManagePaymentMethods />} />
                <Route path="billings-and-payments" element={<BillingSettings />} />
              </Route>
              <Route path={Pathname.profile.basePath} element={<Profile />}>
                <Route index element={<Profile />} />
                <Route path=":profileID" element={<Profile />} />
              </Route>
              <Route path={Pathname.watchlist.basePath} element={<Watchlist />}>
                <Route index element={<Watchlist />} />
                <Route path=":profileID" element={<Watchlist />} />
              </Route>
              <Route path={Pathname.search.basePath} element={<SearchPage />}>
                <Route index element={<SearchPage />} />
                <Route path=":profileID" element={<SearchPage />} />
              </Route>
              <Route path={Pathname.video} element={<VideoDetails />} />
              <Route path={Pathname.album} element={<AlbumDetails />} />
              <Route path={Pathname.player} element={<VideoPlayer />} />
              <Route path={Pathname.playerFormat} element={<VideoPlayer />} />
              <Route path={Pathname.albumPlayer} element={<AlbumPlayer />} />
            <Route path={Pathname.authentication.toTrail} element={<Welcome />} />
              <Route path="*" element={<Navigate to={Pathname.dashboard} />} />
              <Route path={Pathname.plans} element={<PublicPlans userIsSignedIn={userIsSignedIn} />} />
              <Route path={Pathname.purchasePlans} element={<PublicPlans userIsSignedIn={userIsSignedIn} />} />
            </>
          ) : (
            <>
              <Route path={Pathname.home} element={<Access />} />
            <Route path={Pathname.authentication.toTrail} element={<Welcome />} />
              <Route path={Pathname.gettingStarted} element={<GettingStarted />} />
              <Route path={Pathname.access} element={<Access />} />
              <Route path={Pathname.innerpage} element={<InnerPage />} />
              <Route path={Pathname.dashboard} element={<Dashboard />} />
              <Route path={Pathname.yourVideos} element={<YourVideos />} />
              <Route path={Pathname.contentLayout} element={<ContentLayout />} />
              <Route path={Pathname.contentLayoutDashboard} element={<ContentLayoutDashboard />} />
              <Route path={Pathname.contentYourVideosDashboard} element={<ContentLayoutDashboard />} />
              <Route path={Pathname.contentLayoutType} element={<ContentLayoutDetails />} />
              <Route path={Pathname.tvLogin} element={<ManageDevice />} />
              <Route path={Pathname.authentication.mobileVerification} element={<MobileVerification />} />
              <Route path={Pathname.authentication.newmobileVerification} element={<NewMobileVerification />} />
              <Route path={Pathname.authentication.verifyCode} element={<VerifyCode />} />
              <Route path={Pathname.authentication.newverifyCode} element={<NewVerifyCode />} />
              <Route path={Pathname.viewers.basePath}>
                <Route index element={<Viewers />} />
                <Route path="viewerlists" element={<Viewers />} />
                <Route path="groups" element={<Groups />} />
                <Route path="successors" element={<Successors />} />
              </Route>
              <Route path={Pathname.settings.basePath}>
                <Route index element={<AccountSettings />} />
                <Route path="accounts" element={<AccountSettings />} />
                <Route path="notifications" element={<NotificationSettings />} />
                <Route path="manage-payment-methods" element={<ManagePaymentMethods />} />
                <Route path="manageUsers" element={<ConnectedAccountSettings />} />
                <Route path="billings-and-payments" element={<BillingSettings />} />
                <Route path="terms-of-use" element={<TermsAndConditionSettings />} />
                <Route path="subscriber-agreement" element={<SubscriberAgreementSettings />} />
                <Route path="privacy" element={<PrivacySettings />} />
                <Route path="manage-users" element={<UserMangementSettings />} />
              </Route>
              <Route path={Pathname.profile.basePath} element={<Profile />}>
                <Route index element={<Profile />} />
                <Route path=":profileID" element={<Profile />} />
              </Route>
              <Route path={Pathname.watchlist.basePath} element={<Watchlist />}>
                <Route index element={<Watchlist />} />
                <Route path=":profileID" element={<Watchlist />} />
              </Route>

              <Route path={Pathname.search.basePath} element={<SearchPage />}>
                <Route index element={<SearchPage />} />
                <Route path=":profileID" element={<SearchPage />} />
              </Route>
              <Route>
              <Route path="create-user" element={<CreateUser />} />
              </Route>
              <Route>
              <Route path="pay-by-manger" element={<MangerPaymentForUser />} />
              </Route>
              <Route>
              <Route path="affiliate" element={<PlanForAffliate />} />
              </Route>
              <Route>
              <Route path="/referral/:code" element={<Referral />} />
              </Route>
              <Route path={Pathname.video} element={<VideoDetails />} />
              <Route path={Pathname.album} element={<AlbumDetails />} />
              <Route path={Pathname.player} element={<VideoPlayer />} />
              <Route path={Pathname.playerFormat} element={<VideoPlayer />} />
              <Route path={Pathname.albumPlayer} element={<AlbumPlayer />} />
              <Route path="*" element={<Navigate to={Pathname.access} />} />
              <Route path={Pathname.plans} element={<PublicPlans userIsSignedIn={userIsSignedIn} />} />
              <Route path={Pathname.purchasePlans} element={<PublicPlans userIsSignedIn={userIsSignedIn} />} />
              <Route path={Pathname.projectorTermsAndServices} element={<TermsAndServices />} />
            </>
          )
        )
        : (
          <>
            <Route path={Pathname.home} element={<LandingPageExternal />} />
            <Route
              path={Pathname.plans}
              element={<PublicPlans userIsSignedIn={userIsSignedIn} />}
            />
            <Route>
              <Route path="/referral/:code" element={<Referral />} />
            </Route>
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms-of-use" element={<TermsAndConditions />} />
            <Route path="/subscriberAgreement" element={<SubscriberAgreementDarkMode />} />
            <Route path={Pathname.settings.basePath}>
              <Route index element={<Privacy />} />
              <Route path={`${Pathname.settings.basePath}/privacy`} element={<Privacy />} />
              <Route path={`${Pathname.settings.basePath}/terms-of-use`} element={<TermsAndConditions />} />
              <Route path={`${Pathname.settings.basePath}/subscriber-agreement`} element={<SubscriberAgreementDarkMode />} />
            </Route>
            {/* <Route path={Pathname.authentication.signUp} element={<SignUp />} /> */}
            <Route path={Pathname.authentication.newSignUp} element={<NewSignUP />} />
            <Route path={Pathname.authentication.signUp_refferedBy} element={<SignUp />} />
            <Route path={Pathname.authentication.signIn} element={<SignIn />} />
            <Route path={Pathname.authentication.newsignIn} element={<NewSignIn />} />
            <Route path={Pathname.authentication.newsignIn} element={<NewSignIn />} />
            <Route path={Pathname.authentication.forgotPassword} element={<ForgotPassword />} />
            <Route path={Pathname.authentication.verifyEmail} element={<EmailVerification />} />
            <Route path={Pathname.authentication.verifyEmailLink} element={<EmailVerificationLink />} />
            <Route path={Pathname.authentication.mobileVerification} element={<MobileVerification />} />
            <Route path={Pathname.authentication.newmobileVerification} element={<NewMobileVerification />} />
            <Route path={Pathname.authentication.newverifyCode} element={<NewVerifyCode />} />
            <Route path="*" element={<LandingPageExternal userIsSignedIn={userIsSignedIn} />} />
          </>
        )}
    </Routes>
  );
};

export const App = () => {
  const firebaseConfig = {
    apiKey: firebaseCredentials?.apiKey,
    authDomain: firebaseCredentials?.authDomain,
    databaseURL: firebaseCredentials?.databaseURL,
    projectId: firebaseCredentials?.projectId,
    storageBucket: firebaseCredentials?.storageBucket,
    messagingSenderId: firebaseCredentials?.messagingSenderId,
    appId: firebaseCredentials?.appId,
    measurementId: firebaseCredentials?.measurementId,
  };
  const app = initializeApp(firebaseConfig);
  const database = getDatabase(app);
  const dispatch = useDispatch();
  const [firebaseDatabase, setFirebaseDatabase] = useState(database);
  const databaseRefVer = ref(database, 'version');
  const databaseRef = ref(firebaseDatabase, 'status_from_sns');
  const [data, setData] = useState([]);
  useEffect(() => {
    const dataCallback = (snapshot) => {
      if (snapshot.exists()) {
        const dataObj = snapshot.val();
        setData(dataObj);
      }
    };
    onValue(databaseRef, dataCallback);
    return () => {
      off(databaseRef, 'value', dataCallback);
    };
  }, []);

  useEffect(() => {
      const dataCallback = (snapshot) => {
if (snapshot.exists()) {
          const dataObj = snapshot.val();
          if (!localStorage.getItem('version') || localStorage.getItem('version') !== dataObj.toString()) {
    // Clear cookies
    const cookies = document.cookie.split('; ');
    cookies.forEach((cookie) => {
      const [name] = cookie.split('=');
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    });
    // Clear local storage
    localStorage.clear();
    // Clear session storage
    sessionStorage.clear();
    // Clear cache (for some browsers)
    if (window.caches) {
      window.caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          window.caches.delete(cacheName);
        });
      });
    }
          localStorage.setItem('version', dataObj);
        window.location.reload(true);
      }
    }
  };
      onValue(databaseRefVer, dataCallback);
    return () => {
      off(databaseRefVer, 'value', dataCallback);
    };
  }, []);
  const databaseRefMinor = ref(database, 'versionMinor');
  useEffect(() => {
    const dataCallbackMinor = (snapshot) => {
      if (snapshot.exists()) {
        const dataObj = snapshot.val();
        if (!localStorage.getItem('versionMinor') || localStorage.getItem('versionMinor') !== dataObj.toString()) {
          localStorage.setItem('versionMinor', dataObj);
          window.location.reload(true);
        }
      }
    };
    onValue(databaseRefMinor, dataCallbackMinor);
    return () => {
      off(databaseRefMinor, 'value', dataCallbackMinor);
    };
  }, []);
  const [userIsSignedIn, setUserSignInStatus] = useState(false);
  const [removeProjectorTextChange, setRemoveProjectorTextChange] = useState(false);
  const [isS3UploadComplete, setS3UploadComplete] = useState({});
  let totalElapsedTime = 0;
  let totalElapsedSize = 0;
  let totalRemainingSize = 0;
  const [eta, setEta] = useState();
  const uploadDetails = useSelector((state) => state.uploadDetails);
  useEffect(() => {
    const values = Object.values(uploadDetails);
    totalElapsedTime = values.reduce((acc, item) => acc + item.elapsed_time, 0);
    totalElapsedSize = values.reduce((acc, item) => acc + item.elapsed_size, 0);
    totalRemainingSize = values.reduce((acc, item) => acc + item.remaining_size, 0);
    setEta(((totalRemainingSize / (totalElapsedSize / totalElapsedTime) + 1) / 60)?.toFixed(0));
  }, [uploadDetails, eta]);

  const [plansPage, setPlansPage] = useState(false);
  const [autoSigningIn, setAutoSignIn] = useState(true);
  const [fetchDraftID, setfetchDraftID] = useState();
  const addVideoUpdater = useSelector((state) => state.addVideo);
  const [draftCategoryState, setDraftCategoryState] = useState({});
  const [userDetails, setUserDetails] = useState({
    firstname: '',
    lastname: '',
    dpURL: '',
    email: '',
    mobile: '',
    isNewUser: undefined,
    emailNS: undefined,
    subscriptionNS: undefined,
    totalVideoUploaded: undefined,
    totalContentUploaded: undefined,
    totalPhotoUploaded: undefined,
    mfa: undefined,
    trial_used: undefined
  });
  const [formData, setFormData] = useState(new FormData());
  const [videoGroup, setVideoGroup] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [userConnections, setUserConnections] = useState([]);
  const [loadingForAccess, setloadingForAccess] = useState(false);
  const [preferredEmail, setPreferredEmail] = useState('');
  const [isCompleted, setisCompleted] = useState('nothing');
  const [preferredUserDetails, setPreferredUserDetails] = useState({
    firstName: '',
    lastName: ''
  });
  const [uploadContainer, setUploadContainer] = useState([]);
  const [uploadContainer2, setUploadContainer2] = useState([]);
  const [isInActiveUser, setInActiveUser] = useState(false);
  const [currentSelectedPlan, setCurrentSelectedPlan] = useState({
    type: undefined, selected: { isTrial: undefined, selectedPlan: undefined }
  });
  const [appSnackbar, setAppSnackbar] = useState(defaultAppSnackbar);
  const [isViewerListUpdated, setViewerListUpdate] = useState(false);
  const [allPlans, setAllPlans] = useState();
  const [draftContent, setDraftContent] = useState([]);
  const [videoUploadedParts, setVideoUploadedParts] = useState([]);
  const {
    response: plansRes,
    error: plansErr,
    loading: plansLoading,
    refetch: refetchPlans,
  } = useMyAxios(getAllActivePlans, null, 'ALL_ACTIVE_PLANS');

  function base64ToBinary(base64String) {
    const [header, base64Data] = base64String.split(',');
    // Decode base64 into binary
    const binaryString = atob(base64Data);
    const len = binaryString.length;
    const binaryData = new Uint8Array(len);
    // eslint-disable-next-line
    for (let i = 0; i < len; i++) {
      binaryData[i] = binaryString.charCodeAt(i);
    }
    const mimeType = header.match(/:(.*?);/)[1];
    return new Blob([binaryData], { type: mimeType });
  }
  const updateUploadedData = (draft_id, data) => {
    dispatch(saveUploadInfo({ [draft_id]: data }));
  };
  const [uploaderLoader, setUploaderLoader] = useState(false);
  const [holdWhileDelete, setholdWhileDelete] = useState(false);
  const { mutate: autoSignIn } = useSWR([endpoints.getMyProfile], {
    onSuccess: ({ success, data }) => {
      if (success) {
        setUserDetails({
          ...userDetails,
          firstname: data?.firstname,
          lastname: data?.lastname,
          dpURL: data?.image,
          email: data?.email,
          mobile: data?.mobile,
          isNewUser: data?.isNewUser,
          emailNS: data?.email_notification === '1',
          subscriptionNS: data?.sub_notification === '1',
          totalVideoUploaded: data?.totalVideoUploaded,
          totalContentUploaded: data?.totalContentUploaded,
          totalPhotoUploaded: data?.totalPhotoUploaded,
          myId: data?.id,
          mfa: data?.mfa,
          isSubscription: data?.subscription,
          trial_used: data?.trials_used
        });
        localStorage.setItem('id', data?.id);
        setAutoSignIn(false);
        setUserSignInStatus(true);
        setUploaderLoader(false);
      } else {
        // removeToken();
        setUploaderLoader(false);
        setAutoSignIn(false);
        // removeEmailVerified();
      }
    },
    onError: () => {
      removeToken();
      setAutoSignIn(false);
      setUploaderLoader(false);
      removeEmailVerified();
    },
  });
  useEffect(() => {
    const uploadVideoAndSignIn = async () => {
      if (addVideoUpdater[fetchDraftID] === true && isS3UploadComplete[fetchDraftID] === true) {
        const category_id = draftCategoryState[fetchDraftID]?.category_id;
        const thumbnailx = draftCategoryState[fetchDraftID]?.thumbnailx;
        const customThumbnails = draftCategoryState[fetchDraftID]?.customThumbnails;
        const title = draftCategoryState[fetchDraftID]?.title;
        const subcategory_id = draftCategoryState[fetchDraftID]?.subcategory_id;
        const thumbnail_list = draftCategoryState[fetchDraftID]?.thumbnail_list;
        const visibility = draftCategoryState[fetchDraftID]?.visibility;
        const description = draftCategoryState[fetchDraftID]?.description;
        const videoWidth = draftCategoryState[fetchDraftID]?.videoWidth;
        const videoHeight = draftCategoryState[fetchDraftID]?.videoHeight;
        const videoOreintation = draftCategoryState[fetchDraftID]?.videoOreintation;
        const playlist_ids = draftCategoryState[fetchDraftID]?.playlist_ids;
        const video_duration = draftCategoryState[fetchDraftID]?.video_duration;
        const group_ids = draftCategoryState[fetchDraftID]?.videoGroup;

        category_id ? formData.append('category_id', category_id) : null;
        formData.append('title', title);
        subcategory_id && formData.append('subcategory_id', subcategory_id);
        formData.append('visibility', visibility);
        formData.append('description', description);
        formData.append('videoWidth', videoWidth);
        formData.append('videoHeight', videoHeight);
        formData.append('videoOreintation', videoOreintation);
        formData.append('playlist_ids', playlist_ids);
        formData.append('video_duration', video_duration);
        formData.append('group_ids', group_ids);

        try {
          if (thumbnailx !== noPhotoAvailable && customThumbnails?.length > 0) {
            formData.append('thumbnails[]', base64ToBinary(thumbnailx));
          }
          if (thumbnailx !== noPhotoAvailable) {
            formData.append('thumbnail', base64ToBinary(thumbnailx));
          }
        } catch (error) {
          console.error(error.message);
        }

        if (customThumbnails?.length > 0 && thumbnailx !== customThumbnails[0]) {
          formData.append('thumbnails[]', base64ToBinary(customThumbnails[0]));
        }

        thumbnail_list.forEach((file, index) => {
          formData.append(
            'thumbnails[]',
            file,
            `thumbnail_${index + (customThumbnails?.length > 0 ? 2 : 1)}.jpg`
          );
        });

        try {
          await addVideo(formData); // Wait for addVideo to complete
          setUploadContainer2((prevUploadContainer) => prevUploadContainer?.filter((id) => id !== fetchDraftID));
          await autoSignIn(); // Then call autoSignIn
          setS3UploadComplete((prevStatus) => ({
            ...prevStatus,
            [fetchDraftID]: false,
          }));
          updateUploadedData(fetchDraftID, 'completed');
        } catch (error) {
          console.error('Error during upload or sign-in:', error);
        }
      }
    };

    uploadVideoAndSignIn(); // Call the async function inside the useEffect
  }, [addVideoUpdater, isS3UploadComplete]);

  const { isValidating: gettingUserConnections, mutate: getUserConnections } = useSWR([endpoints.getAllViewRequestSent, 1], {
    fetcher: (url, status) => dataFetcher(url, { status }),
    onSuccess: ({ success, data }) => {
      if (success) {
        setUserConnections(data);
      } else {
        setUserConnections([]);
      }
      setloadingForAccess(false);
    },
    onError: () => {
      setUserConnections([]);
      setloadingForAccess(false);
     },
  });
  const [toastForGroup, setToastForGroup] = useState('');
  const [subscriptionDetails, setSubscriptionData] = useState();
  const [subDetails, setSubDetails] = useState();
  const [langText, setLangText] = useState(undefined);
  const [mangerFunctionData, setmangerFunctionData] = useState(false);
  const [messaged, setmessaged] = useState('');
  const [referralStatus, setReferralStatus] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const browserLang = detectBrowserLanguage();
  const [showProgressBox, setProgressBox] = useState(false);
  const [uploadingVideo, setUploadingVideo] = useState([]);
  const [uploadingVideoDetails, setUploadingVideoDetails] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(undefined);
  const [uploadStatus, setUploadStatus] = useState(undefined);
  const [visibility, setVisibility] = useState(true);
  const [videoDetail, setVideoDetail] = useState(undefined);
  const [startFlowEvent, startingFlowEvent] = useState(false);
  const [profileLoader, setProfileLoader] = useState(false);
  const [isSingleUpload, setSingleUpload] = useState(undefined);
  const [closeModal, setModalVisibility] = useState(false);
  const [uniqueId, setUniqueId] = useState(undefined);
  const [pageLoad, setPageLoad] = useState(false);
  const [videoCount, setVideoCount] = useState(0);
  const [refreshContent, setRefreshContent] = useState(false);
  const [errorMsg, setErrorMsg] = useState(undefined);
  const [selectedUploadFiles, setSelectedUploadFiles] = useState([]);
  const [s3Data, setS3Data] = useState({});
  const [uploadPercentages, setUploadPercentages] = useState([]);
  const [lineReq, setLineReq] = useState(false);
  const [apiCALL, setapiCALL] = useState(undefined);
  const [uploadingInProgress, setUploadingInProgress] = useState(null);
  const [toastVisiblity, setToastVisiblity] = useState(true);
  const [pubLoader, setPubLoader] = useState(false);
  const [iamUploading, setIamUploading] = useState(false);
  const [bigUserData, setbigUserData] = useState({});
  const [textOfUpload, settextOfUpload] = useState('Publish');
  const [invalidErr, setinvalidErr] = useState();
  const [isAddDraftDone, setisAddDraftDone] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [helpNeed, setHelpNeed] = useState(false);
  const [dataOfCategory, setdataOfCategory] = useState();
  const [profileName, setProfileName] = useState();
  const [contextLoading, setContextLoading] = useState(false);
  const [singleEdit, setSingleEdit] = useState(false);
  const [amICalled, setamICalled] = useState(false);
  const [loader, setLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  const [photoAlbumLoader, setphotoAlbumLoader] = useState(false);
  const [needToShowETA, setNeedToShowETA] = useState(false);
  const [theGrtLoader, settheGrtLoader] = useState(false);
  const [categoryLoader, setcategoryLoader] = useState(undefined);
  const uploadDataPercentages = useSelector((state) => state.upload);
  const [cardNumber, setCardNumber] = useState('');
  const [cvvNumber, setCvvNumber] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [firstTimeOnlychecked, setFirstTimeOnlyChecked] = useState(false);
  const [cardAdded, setcardAdded] = useState(false);
  const [isTrail, setIsTrail] = useState('0');
  const [uploadQueue, setUploadQueue] = useState([]);
  const [activeUploads, setActiveUploads] = useState([]);
  const [draftOfUpload, setDraftOfUpload] = useState([]);
  const [totalDraft, setTotalDraft] = useState([]);
  const [allvideoData, setAllvideoData] = useState([]);
  const [activeModalId, setActiveModalId] = useState(null);
  const [isUploadingBulky, setIsUploadingBulky] = useState(false);
  const [uploadProcessing, setUploadProcessing] = useState(false);
  const [disableDrag, setdisableDrag] = useState(false);
  const [didIOpenModal, setdidIOpenModal] = useState(false);
  const [openPaymentSuccess, setOpenPaymentSuccess] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [overlay, setOverlay] = useState(false);
  const [trailVideos, setTrailVideos] = useState(10);
  const [trailModalVisible, setTrailPopUpModalVisible] = useState(false);
  const [selectedPlan, selectPlan] = useState(undefined);
  const [isFromAffl, setisFromAffl] = useState(false);
  const [affliatePlanId, setAffliatePlanId] = useState(11);
  const [expressloading, setExpressLoading] = useState(false);
  const MAX_VIDEOS = subscriptionDetails?.subscription?.is_trial === '1' ? trailVideos : 100;
  const MAX_SIZE_GB = 30;
  const MAX_SIZE_BYTES = MAX_SIZE_GB * 1024 * 1024 * 1024; // Convert 2GB to bytes
  const allowedExtensions = ['mp4', 'mov', 'mpg', 'm4v', 'm2ts'];
  useEffect(() => {
    // Combine all `draft_id`s from `allvideoData`, default to empty arrays if undefined
    const allDraftIds = [
      ...(allvideoData?.video?.map((item) => item?.draft_id) || []),
      ...(allvideoData?.videoDraftContents?.map((item) => item?.draft_id) || []),
    ];
    // Filter out matching `draft_id`s from `draftOfUpload`
    setDraftOfUpload((prevDrafts) => prevDrafts?.filter((draftId) => !allDraftIds?.includes(draftId)));
    setTotalDraft(allDraftIds);
  }, [allvideoData]);

  const setLoadingState = (isLoading) => {
    setContextLoading(isLoading);
  };
  const updateUploadPercentages = (uploadPercentages) => {
    setUploadPercentages(uploadPercentages);
  };
  useEffect(() => {
    if (userDetails?.totalContentUploaded !== undefined) {
      const remainingUploads = Math.max(10 - (uploadContainer2?.length + userDetails?.totalContentUploaded), 0);
      setTrailVideos(remainingUploads);
    }
  }, [userDetails?.totalContentUploaded, uploadContainer2]);
  const { isValidating: gettingVideoIdFromFlow, mutate: getVideoIdFromFlow } = useSWR([endpoints.getVideoIdFromFlow, uniqueId], {
    fetcher: (url, flowIdentifier) => dataFetcher(url, { flowIdentifier }),
    onSuccess: ({ success, video_id }) => {
      if (success) {
        const copyVideo = [...uploadingVideoDetails];
        const foundIndex = copyVideo.findIndex((x) => x.fileId === uniqueId && x.id === null);
        if (foundIndex !== -1 && video_id) {
          copyVideo[foundIndex] = { ...copyVideo[foundIndex], id: video_id };
          // setUploadingVideoDetails(copyVideo);
        }
      } else {
        // setUserConnections([]);
      }
    },
    onError: () => {
      setUserConnections([]);
    },
  });

  useEffect(() => {
    if (!plansLoading && plansRes && plansRes?.data) {
      setAllPlans(plansRes?.data);
    }
  }, [plansRes, plansLoading]);

  // useEffect(() => {
  //   if (videoDetail) {
  //     setUploadingVideoDetails([...uploadingVideoDetails, videoDetail]);
  //     setVideoDetail(undefined);
  //   }
  // }, [videoDetail]);

  // useEffect(() => {
  //   const currentVideo = uploadingVideoDetails.find((x) => x.fileId === uniqueId);
  //   if (!currentVideo?.id && uniqueId) {
  //     getVideoIdFromFlow();
  //   }
  // }, [uploadingVideoDetails, uniqueId]);

  useEffect(() => {
    if (uploadingVideoDetails?.length && videoCount && !uploadingVideoDetails.filter((v) => v.id === null)?.length) {
      setProgressBox(true);
      setModalVisibility(true);
      setVideoCount(0);
    } else {
      setModalVisibility(false);
    }
  }, [uploadingVideoDetails, videoCount]);
  useEffect(() => {
    if (uploadProgress) {
      const copyVideo = [...uploadingVideoDetails];
      const foundIndex = copyVideo.findIndex((x) => x.fileId === uploadProgress.fileId);
      if (foundIndex !== -1) {
        copyVideo[foundIndex] = { ...copyVideo[foundIndex], progress: uploadProgress.progress, status: 'Uploading' };
        setUploadingVideoDetails(copyVideo);
      }
    }
  }, [uploadProgress]);
  useEffect(() => {
    if (isSingleUpload) {
      setUploadingVideoDetails([]);
    }
  }, [isSingleUpload]);

  useEffect(() => {
    if (uploadStatus) {
      const { progress, status, size, id, videoDuration, suggestedThumbnails } = uploadStatus;
      const copyVideo = [...uploadingVideoDetails];
      const foundIndex = copyVideo.findIndex((x) => x.fileId === uploadStatus.fileId);
      if (foundIndex !== -1) {
        copyVideo[foundIndex] = { ...copyVideo[foundIndex], progress, status, size, id, videoDetail, suggestedThumbnails, videoDuration };
        setUploadingVideoDetails(copyVideo);
      }
    }
  }, [uploadStatus]);

  const updateVideoDetails = (file, uniqueIdentifier) => {
    const newVideo = {
      progress: '0%',
      name: file.name,
      size: parseFloat((file.size / 1048576).toFixed(2)),
      fileId: uniqueIdentifier,
      status: 'Uploading',
      boxVisibility: 1,
      id: null,
      description: '',
      published: 0
    };
    setUploadingVideoDetails((prevDetails) => [...prevDetails, newVideo]);
  };
  // TODO remove flowEventHandler completely
  const flowEventHandler = () => {
    setModalVisibility(false);
    const flow = new window.Flow({
      target: `${baseUrl?.URL}${endpoints.addVideoContentArray}`,
      testChunks: false,
      query: {
        token: getToken(),
      },
      fileParameterName: 'video_file[]'
    });
    if (flow?.support) {
      flow.assignBrowse(document.getElementById('video-file'));
      flow.on('fileAdded', (file, evt) => {
        startingFlowEvent(false);
        const foundIndex = uploadingVideoDetails.findIndex((x) => x.fileId === file.uniqueIdentifier);
        if (foundIndex !== -1) {
          flow.off();
          startingFlowEvent(false);
          setModalVisibility(true);
          setErrorMsg('Video already added');
        } else {
          setErrorMsg(undefined);
          setVideoDetail({
            progress: '0%',
            name: file.name,
            size: parseFloat((file.size / 1048576).toFixed(2)),
            fileId: file.uniqueIdentifier,
            status: 'Uploading',
            boxVisibility: 1,
            id: null,
            description: '',
            published: 0
          });
          if (evt?.target?.files?.length === 1 && !showProgressBox) {
            setSingleUpload(file.file);
            flow.off();
          } else {
            setUniqueId(file.uniqueIdentifier);
          }
        }
      });
      flow.on('filesAdded', (array, event) => {
        setVideoCount(array?.length);
      });
      flow.on('filesSubmitted', (arr, evt) => {
        flow.upload();
      });
      flow.on('fileProgress', (file, chunk) => {
        setUniqueId(file.uniqueIdentifier);
        let videoProgress = ((chunk.offset + 1) / file.chunks.length) * 100;
        videoProgress = `${parseInt(videoProgress, 10)}%`;
        setUploadProgress({
          fileId: file.uniqueIdentifier,
          progress: videoProgress,
        });
        setProgressBox(true);
      });
      // (C6) ON UPLOAD SUCCESS
      flow.on('fileSuccess', (file, message, chunk) => {
        const resp = JSON.parse(message);
        setUploadStatus({
          fileId: file.uniqueIdentifier,
          status: 'Completed',
          progress: '100%',
          id: resp?.video_id,
          videoDuration: resp?.video_duration,
          size: resp?.video_size,
          suggestedThumbnails: resp?.suggestedThumbnails
        });
      });
      // (C7) ON UPLOAD ERROR
      flow.on('fileError', (file, message) => {
        setUploadStatus({
          fileId: file.uniqueIdentifier,
          status: 'Error'
        });
      });
      // flow.isComplete(() => {
      // });
      flow.on('complete', (message, file, chunk) => {
      });
    }
  };

  /**
   * Uploadfiles called whenever selectedUploadFiles changes, whcih will be changes based on modal input selection
   * check for the selectedUploadFiles array wether contains one or more files, and if >1 show progress box with each files progress
   */
  const uploadFiles = useCallback(() => {
    setModalVisibility(false);
    let callMultipleApi = true;
    selectedUploadFiles.forEach((selectedFile) => {
      const { file } = selectedFile;
      const formData = new FormData();
      if (selectedUploadFiles?.length === 1 && !showProgressBox) {
        setSingleUpload(selectedUploadFiles[0]?.file);
        callMultipleApi = false;
      } else {
        setUniqueId(selectedFile.uniqueIdentifier);
      }

      const foundIndex = uploadingVideoDetails.findIndex((x) => x.fileId === selectedFile.uniqueIdentifier);
      if (foundIndex !== -1) {
        setModalVisibility(true);
        setErrorMsg('Video already added');
        callMultipleApi = false;
      } else {
        setErrorMsg(undefined);
        updateVideoDetails(file, selectedFile.uniqueIdentifier);
        formData.append('video_file[]', file);
      }
      if (callMultipleApi) {
        setVideoCount(1);
        setProgressBox(true);
        axios
          .post(`${baseUrl?.URL}${endpoints.addVideoContentArray}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data', Authorization: getToken() },
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              const percent = Math.floor((loaded * 100) / total);
              setUploadProgress({
                fileId: selectedFile.uniqueIdentifier,
                progress: `${percent} %`
              });
            }
          })
          .then((res) => {
            setUploadStatus({
              fileId: selectedFile.uniqueIdentifier,
              status: 'Completed',
              progress: '100%',
              id: res?.data?.video_id,
              videoDuration: res?.data?.video_duration,
              size: res?.data?.video_size,
              suggestedThumbnails: res?.data?.suggestedThumbnails
            });
          })
          .catch((error) => {
            setUploadStatus({
              fileId: selectedFile.uniqueIdentifier,
              status: 'Error'
            });
          });
      }
    });
  }, [selectedUploadFiles]);
  const { isValidating: gettingSubscription, mutate: getSubscription } = useSWR([endpoints.getMySubscription], {
    fetcher: (url) => dataFetcher(url),
    onSuccess: (response) => {
      if (response.success) {
        const { subscription, planaction } = response;
        setSubDetails({ ...response });
        setInActiveUser(!response?.has_activePlan || (response?.has_activePlan && parseInt(response?.subscription?.subscription_status, 10) === 2));
        setReferralStatus(response?.referral_status);
      } else {
        setSubDetails({ subscription: { plan: 'Free' } });
      }
    },
    onError: () => { setSubDetails({ subscription: { plan: 'Free' } }); },
  });
  const [getAccessChecker, setGetAccessChecker] = useState();
  useEffect(() => {
    if (selectedUploadFiles.length > 0) {
      uploadFiles();
    }
  }, [selectedUploadFiles, uploadFiles]);
  // useEffect(() => {
  //   if (startFlowEvent) {
  //     flowEventHandler();
  //   }
  // }, [startFlowEvent]);
  const [expressData, setExpressData] = useState();
  const expressCheckout = async (Id) => {
    const userData = apiCALL?.data?.userDetails[0] || bigUserData;
    const userId = userData?.id || userData?.userId || localStorage.getItem('temp_user_id');
         try {
           const myToken = await getToken();
           const apiUrl = `${baseUrl}/addPlan`;
           const requestBody = {
            promo: localStorage.getItem('promocode'),
            ...(localStorage.getItem('affiliate') !== 'undefined' && { affiliateCode: localStorage.getItem('affiliate') }),
             token: myToken,
             plan_id: localStorage.getItem('planId'),
             is_free: 0,
             ...(isFromAffl && { managerCard: localStorage.getItem('managerCard') === '1' }),
             //  ...(isFromAffl && { userId: localStorage.getItem('affliate_userId') }),
             ...(localStorage.getItem('affiliate') !== 'undefined' && { userId: localStorage.getItem('affliate_userId') }),
             trial: localStorage.getItem('trial') === '1',
             ...(Id && { checkout_id: Id })
           };
           const response = await axios.post(apiUrl, requestBody);
           setExpressData(response?.data);
           setAppSnackbar({
             isVisible: true,
             type: 'success',
             message: response?.data?.message
           });
           if (localStorage.getItem('affliate_userId')) {
             setPaymentStatus('affliate');
           } else {
             setPaymentStatus('success');
           }
          setOpenPaymentSuccess(true);
           getSubscription();
           autoSignIn();
           setExpressLoading(false);
           localStorage.removeItem('promocode');
           localStorage.removeItem('affiliate');
           localStorage.removeItem('trial');
           localStorage.removeItem('affliate_userId');
           localStorage.removeItem('managerCard');
          //  localStorage.removeItem('planId');
         } catch (error) {
           const responseData = error?.response?.data?.message;
           console.error('error', error);
           localStorage.removeItem('promocode');
           localStorage.removeItem('affiliate');
           localStorage.removeItem('trial');
           localStorage.removeItem('managerCard');
           localStorage.removeItem('planId');
           setExpressLoading(false);
           if (localStorage.getItem('affliate_userId')) {
            setPaymentStatus('affliate_error');
          } else {
            setPaymentStatus('error');
           }
           localStorage.removeItem('affliate_userId');
          //  setOpenPaymentSuccess(true);
           setAppSnackbar({
             isVisible: true,
             type: 'error',
             message: responseData || 'Oops! Something went wrong while purchasing plan',
           });
         }
       };
  useEffect(() => {
    console.log(window.location.pathname);
    if (window.location.pathname === '/') {
      window.location.href = 'https://www.projectorstream.com';
    } else if (window.location.pathname.includes('autoLogin')) {
      const params = new URLSearchParams(window.location.search);
      const routed_token = params.get('token');
      const routed_path = params.get('path');
      // if (userIsSignedIn === false && getToken() === null) {
      localStorage.setItem('token', routed_token);
      // }
      window.location.href = routed_path;
    } else if (
      window.location.pathname.includes('success-payment')
      && new URLSearchParams(window.location.search).get('checkout_id')
    ) {
      const params = new URLSearchParams(window.location.search);
      const id = params.get('checkout_id');
      setTimeout(() => {
        expressCheckout(id);
      }, 100);
    } else if (window.location.pathname.includes('cancel')) {
      localStorage.removeItem('code');
      alert('Purchase Cancelled');
    }
  }, []);
  useEffect(() => {
    if (subDetails && (subDetails !== subscriptionDetails)) {
      setSubscriptionData(subDetails);
    }
  }, [subDetails]);

  const { isValidating: gettingLangFile, mutate: getLanguageFile } = useSWR([endpoints.getLangFile, browserLang], {
    fetcher: (url, lang) => dataFetcher(url, { lang }),
    onSuccess: (response) => {
      if (response.success) {
        setLangText(JSON.parse(response.data));
      } else {
        setLangText(undefined);
      }
    },
    onError: () => {
      setLangText(undefined);
    },
  });
const [mangerToken, setMangerToken] = useState();
const [topOrBottom, setTopOrBottom] = useState(true);
const [userAccessedData, setuserAccessedData] = useState();
const [trialVal, setTrial] = useState(undefined);
const [cardPayId, setcardPayId] = useState();
const [cardUpdate, setcardUpdate] = useState(false);
  const [cardUpdateBoolean, setcardUpdateBoolean] = useState(false);
  const daysOfMonths = '30';
const [isTokenChange, setIsTokenChange] = useState(false);
const [affiliateCode, setAffiliatedCode] = useState('');
const [encounterLoading, setEncounterLoading] = useState(false);
  const handleClick = (e) => {
    setuserAccessedData(e);
    const newToken = e.token;
    setToken(newToken);
    setIsTokenChange(true);
  };
  const handleManger = (e) => {
    const newToken = e?.data?.manager_token;
    setToken(newToken);
  };

  /**
   * Get user profile using token as payload
   */
  const forceAutoSignin = () => {
    setAutoSignIn(true);
    autoSignIn();
  };

  useEffect(() => {
    const detectBrowserLanguage = require('detect-browser-language');
    getLanguageFile();
    const htmlClass = document.getElementsByTagName('html')[0].classList;

    window.onresize = () => {
      htmlClass.add(styles.changingTheme);
      setTimeout(() => htmlClass.remove(styles.changingTheme), 500);
    };
  }, []);

  useEffect(() => {
    const token = getToken();
    const isEmailVerified = getEmailVerified();
    if (token) {
      setAutoSignIn(true);
      autoSignIn();
    } else {
      setAutoSignIn(false);
    }
  }, [autoSignIn]);
  const [apiResponse, setApiResponse] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const fetchData = async () => {
    try {
      const myToken = await getToken();
      const apiUrl = `${baseUrl}/affiliateDetails`;
      const requestBody = {
        token: myToken,
      };
      const response = await axios.post(apiUrl, requestBody);
      setApiResponse(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (userIsSignedIn) {
      setloadingForAccess(true);
      getUserConnections();
      getSubscription();
    }
  }, [userIsSignedIn, getUserConnections]);
  const referralCode = localStorage.getItem('code');
  const [holdTheWeb, setHoldTheWeb] = useState(false);
  const disableScroll = () => {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    document.body.style.position = 'fixed';
    document.body.style.top = `-${scrollPosition}px`;
    document.body.style.width = '100%'; // Set width to 100%
  };
  const enableScroll = () => {
    const scrollPosition = parseInt(document.body.style.top || '0', 10);
    document.body.style.position = '';
    document.body.style.top = '';
    window.scrollTo(0, -scrollPosition);
  };
  useEffect(() => {
    holdTheWeb === true
    ? disableScroll()
    : enableScroll();
  }, [holdTheWeb]);
  const [uploadPercentagesInNumber, setUploadPercentagesInNumber] = useState();
  const [droper, setDroper] = useState(false);
  const [videoFiles, setVideoFiles] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [addMorePhoto, setaddMorePhoto] = useState(false);
  const [uploadModalIsVisible, setUploadModalVisibility] = useState(false);
const [popUpModalVisible, setPopUpModalVisible] = useState(false);
const [buttonText, setButtonText] = useState('Save to Draft');
const [navigateOnceClicked, setNavigateOnceClicked] = useState(false);
const [editLoader, setEditLoader] = useState(false);
const [profileDetails, setProfileDetails] = useState({
  firstname: undefined,
  lastname: undefined,
  email: undefined,
  image: undefined,
  type: undefined
});
const [open, setOpen] = useState(false);

const handleOpen = () => setOpen(true);
const handleSingleDrop = () => {
  setDroper(false);
  // setCrossDrag(false);
};
const revealUploadModal = (videoFiles) => {
  const totalSize = videoFiles?.reduce((acc, file) => acc + file.size, 0);
  localStorage.setItem('opened_draft_id', null);
  autoSignIn().then((res) => {
    if (!res?.data?.subscription && res?.data?.totalContentUploaded >= ALLOWED_UPLOADS_COUNT) {
      setPopUpModalVisible(true);
      setEditLoader(false);
      setOpen(false);
    } else {
      setUploadModalVisibility(true);
      setEditLoader(false);
    }
  });
  const invalidFiles = videoFiles.filter((file) => {
    const extension = file.name.split('.').pop().toLowerCase();
    return !allowedExtensions.includes(extension);
  });
  if (invalidFiles.length > 0) {
    setAppSnackbar({ isVisible: true, type: 'error', message: 'Invalid file format. Allowed formats are: .mp4, .mov, .mpg, .m4v, .m2ts' });
    // alert('Invalid file format. Allowed formats are: .mp4, .mov, .mpg, .m4v, .m2ts');
    return;
  }
  if (userDetails?.isSubscription === null) {
    if (videoFiles?.length <= 1 && totalSize <= MAX_SIZE_BYTES && userDetails?.totalVideoUploaded === 0) {
      handleOpen();
    } else {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'One free upload allowed' });
    }
  } else if (userDetails?.isSubscription !== null) {
    if (videoFiles?.length <= MAX_VIDEOS && totalSize <= MAX_SIZE_BYTES) {
      handleOpen();
    } else {
      const errorMessage = videoFiles?.length > MAX_VIDEOS
        ? `Maximum of ${MAX_VIDEOS} videos allowed`
        : 'Total size of videos should not exceed 2GB';
      setAppSnackbar({ isVisible: true, type: 'error', message: errorMessage });
    }
  }
};
  const paymentNavigation = (route) => {
setOpenPaymentSuccess(false);
navigateTo(route);
};
const dragOver = (e) => {
  e.preventDefault();
  setDroper(true);
};
const dragLeave = (e) => {
  e.preventDefault();
  setDroper(false);
};
const hideUploadModal = () => {
  setUploadModalVisibility(false);
};
// useEffect(() => {
//   document.addEventListener('dragover', dragOver);
//   document.addEventListener('dragleave', dragLeave);
//   return () => {
//     document.removeEventListener('dragover', dragOver);
//     document.removeEventListener('dragleave', dragLeave);
//   };
// }, []);
const MultiSelect = () => {
  setOverlay(false);
  const files = Array.from(selectedFiles);
  const videoFiles = files.filter((file) => file.type.startsWith('video/'));
  const imageFiles = files.filter((file) => file.type.startsWith('image/'));
  setVideoFiles(videoFiles);
  setImageFiles(imageFiles);
  revealUploadModal(videoFiles);
  setDroper(false);
};
useEffect(() => {
  if (selectedFiles?.length > 1) {
    MultiSelect();
  }
  }, [selectedFiles]);
const handleDrop = (e) => {
  setOverlay(false);
  e.preventDefault();
  const files = Array.from(e.dataTransfer.files);
  const videoFiles = files.filter((file) => file.type.startsWith('video/'));
  const imageFiles = files.filter((file) => file.type.startsWith('image/'));
  setVideoFiles(videoFiles);
  setImageFiles(imageFiles);
  revealUploadModal(videoFiles);
  setDroper(false);
};
// const [cardNumberStripe, setCardNumberStripe] = useState('');
// const [inputValue, setInputValue] = useState('');
// const [expiryMonth, setExpiryMonth] = useState('');
// const [expiryYear, setExpiryYear] = useState('');
// const [cvc, setCvc] = useState('');
// const [stripeToken, setStripeToken] = useState([]);
// const [error, setError] = useState(null);
// const callStripeAPI = async () => {
//   const datax = new URLSearchParams();
//   datax.append('card[number]', cardNumberStripe);
//   datax.append('card[exp_month]', expiryMonth);
//   datax.append('card[exp_year]', expiryYear);
//   datax.append('card[cvc]', cvc);
//   try {
//     const response = await axios.post('https://api.stripe.com/v1/tokens', data, {
//       headers: {
//         'Content-Type': 'application/x-www-form-urlencoded',
//         'Authorization': 'Bearer pk_live_51OyTOEKaFrbWdXakXgNskBU4TmXmqTIP6thT1vf8LjiR1FADapz2jX6Fi5a1h8KpG7hCAiEK9EnlrKbH7wDVGjOs00ReGilVdx',
//       },
//     });
//     setStripeToken(response.data);
//     setError(null);
//   } catch (error) {
//     setError(error.response?.data?.error?.message || 'Error occurred while contacting Stripe API');
//     console.error('Error:', error);
//   }
// };
  return (
    <AuthContext.Provider
      value={{
        forceAutoSignin,
        getToken,
        preferredEmail,
        removeToken,
        userIsSignedIn,
        setPreferredEmail,
        preferredUserDetails,
        setPreferredUserDetails,
        setToken,
        setUserSignInStatus,
        setPlansPage,
        plansPage,
        isInActiveUser,
        gettingSubscription,
        getEmailVerified,
        setEmailVerified,
        removeEmailVerified
      }}
    >
      <AppContext.Provider
        value={{
          setOpen,
          expressloading,
          affliatePlanId,
            setAffliatePlanId,
          setExpressLoading,
          isFromAffl,
          setisFromAffl,
          selectedPlan,
          selectPlan,
          expressCheckout,
          cardUpdateBoolean,
          setcardUpdateBoolean,
          trailModalVisible,
          setTrailPopUpModalVisible,
          uploadContainer2,
          setUploadContainer2,
          cardPayId,
          setcardPayId,
          cardUpdate,
          setcardUpdate,
          trialVal,
          setTrial,
          didIOpenModal,
          allvideoData,
          setAllvideoData,
          isCompleted,
          setisCompleted,
          firstTimeOnlychecked,
          setFirstTimeOnlyChecked,
          cardAdded,
          setcardAdded,
          navigateOnceClicked,
          buttonText,
          setNavigateOnceClicked,
          MAX_VIDEOS,
          trailVideos,
          activeModalId,
          setActiveModalId,
          isUploadingBulky,
          setIsUploadingBulky,
          uploadQueue,
          setUploadQueue,
          activeUploads,
          setActiveUploads,
          draftOfUpload,
          setDraftOfUpload,
          totalDraft,
          uploadProcessing,
          setUploadProcessing,
          setdidIOpenModal,
          disableDrag,
          setdisableDrag,
          isTrail,
          setIsTrail,
          removeProjectorTextChange,
          setRemoveProjectorTextChange,
          dataOfCategory,
          profileDetails,
          addMorePhoto,
          videoGroup,
          setVideoGroup,
          selectedFiles,
          setSelectedFiles,
          setaddMorePhoto,
          setfetchDraftID,
          setProfileDetails,
          setFormData,
          isAddDraftDone,
          pubLoader,
          cardNumber,
          profileLoader,
          setProfileLoader,
          setCardNumber,
          cvvNumber,
          setCvvNumber,
          expirationDate,
          setExpirationDate,
          setPubLoader,
          setisAddDraftDone,
          draftCategoryState,
          setDraftCategoryState,
          isS3UploadComplete,
          setS3UploadComplete,
          setdataOfCategory,
          selectedItems,
          setSelectedItems,
          singleEdit,
          encounterLoading,
          loader,
          setLoader,
          setEncounterLoading,
          needToShowETA,
          setNeedToShowETA,
          setSingleEdit,
          setUploadContainer,
          uploadContainer,
          uploaderLoader,
          setUploaderLoader,
          holdWhileDelete,
          setholdWhileDelete,
          referralCode,
          visible,
          setVisible,
          textOfUpload,
          setLoadingState,
          contextLoading,
          setContextLoading,
          profileName,
          setProfileName,
          amICalled,
          setamICalled,
          settextOfUpload,
          setHelpNeed,
          s3Data,
          fetchData,
          invalidErr,
          setinvalidErr,
          isInvalid,
          setIsInvalid,
          apiResponse,
          isLoading,
          topOrBottom,
          setTopOrBottom,
          autoSignIn,
          uploadPercentagesInNumber,
          setUploadPercentagesInNumber,
          getAccessChecker,
          setGetAccessChecker,
          iamUploading,
          setIamUploading,
          isTokenChange,
          setIsTokenChange,
          setS3Data,
          affiliateCode,
          setAffiliatedCode,
          handleManger,
          lineReq,
          setLineReq,
          messaged,
          setmessaged,
          mangerFunctionData,
          setmangerFunctionData,
          handleClick,
          bigUserData,
          setbigUserData,
          setMangerToken,
          userAccessedData,
          setHoldTheWeb,
          toastForGroup,
          setToastForGroup,
          getUserConnections,
          gettingUserConnections,
          setAppSnackbar,
          setUserConnections,
          setUserDetails,
          userConnections,
          userDetails,
          subscriptionDetails,
          getSubscription,
          setPlansPage,
          plansPage,
          currentSelectedPlan,
          setCurrentSelectedPlan,
          langText,
          setReferralStatus,
          referralStatus,
          isViewerListUpdated,
          setViewerListUpdate,
          showProgressBox,
          setProgressBox,
          uploadingVideo,
          setUploadingVideo,
          uploadingVideoDetails,
          setUploadingVideoDetails,
          uploadProgress,
          setUploadProgress,
          visibility,
          setVisibility,
          flowEventHandler,
          startingFlowEvent,
          isSingleUpload,
          eta,
          closeModal,
          apiCALL,
          setapiCALL,
          setSingleUpload,
          pageLoad,
          removeToken,
          setPageLoad,
          setVideoCount,
          videoCount,
          errorMsg,
          setModalVisibility,
          setUniqueId,
          setAllPlans,
          allPlans,
          selectedUploadFiles,
          setSelectedUploadFiles,
          draftContent,
          setDraftContent,
          setVideoUploadedParts,
          daysOfMonths,
          refreshContent,
          setRefreshContent,
          uploadPercentages,
          updateUploadPercentages,
          firebaseDatabase,
          setUploadingInProgress,
          uploadingInProgress,
          photoAlbumLoader,
          setphotoAlbumLoader,
          theGrtLoader,
          settheGrtLoader,
          categoryLoader,
          setcategoryLoader
        }}
      >
        <div
             className={styles.app}
             onDrop={disableDrag === true ? handleDrop : handleSingleDrop}
             onDragOver={(e) => dragOver(e)}
             onDragLeave={(e) => dragLeave(e)}
        >
{/* <Dialog open={openPaymentSuccess}>
  <DialogTitle className={styles.dialogTitle}>
    {paymentStatus === 'success' || paymentStatus === 'affliate' ? '🎉 Payment Successful! 🎉' : '❌ Payment Unsuccessful'}
  </DialogTitle>

  <DialogContent className={styles.dialogContent}>
  {paymentStatus === 'success' || paymentStatus === 'affliate' ? (
    'Your payment was processed successfully.'
  ) : (
    <>
      There was an issue processing your payment.
{' '}
<br />
{' '}
Please try again.
    </>
  )}
  </DialogContent>

  <Button
    className={styles.dialogButton}
    onClick={() => {
      setOpenPaymentSuccess(false);
      if (paymentStatus === 'affliate' || paymentStatus === 'affliate_error') {
        paymentNavigation('/settings/manage-users'); // Navigate to Dashboard
      } else if (paymentStatus === 'success') {
        paymentNavigation('/dashboard'); // Navigate to Dashboard
      } else {
        paymentNavigation('/settings/billings-and-payments'); // Navigate to Billing
      }
    }}
  >
    {paymentStatus === 'success' ? 'Go To Dashboard' : paymentStatus === 'affliate' || 'affliate_error' ? 'Go To User Management' : 'Go To Billing'}
  </Button>
</Dialog> */}
          {openPaymentSuccess
            && <SuccessPopUp expressData={expressData} paymentNavigation={paymentNavigation} paymentStatus={paymentStatus} onHide={() => setOpenPaymentSuccess(false)} />}
          {autoSigningIn || loadingForAccess
            ? (
              <div className={styles.autoSignInSpinnerContainer}>
                <CircularProgress />
              </div>
            )
            : (
              <Router>
                <RouteSwitch
                  {...{
                    setUserSignInStatus,
                    isNewUser: userDetails?.isNewUser,
                    userIsSignedIn,
                    isInActiveUser
                  }}
                />
              </Router>
            )}
        </div>

        <Snackbar
          autoHideDuration={appSnackbar?.duration}
          isVisible={appSnackbar?.isVisible}
          message={appSnackbar?.message || ''}
          onClose={() => { setAppSnackbar(defaultAppSnackbar); }}
          type={appSnackbar?.type}
        />
      {/* {window.location.pathname === Pathname.access && ( */}
      {needToShowETA === true && (
        uploadContainer?.length > 0 && (
          <div className={styles.fullSpeed}>
            {!Number.isNaN(eta) && (
              <div className={styles.Sppped}>
                {eta > 1 ? `${eta} Minutes remaining` : eta <= 1 ? 'Less than a minute' : 'Calculating'}
              </div>
            )}
            <button className={styles.hideOrShow} onClick={() => setToastVisiblity(!toastVisiblity)}>
              {toastVisiblity !== true ? (
                <img src={DownArrow} alt="" />
              ) : (
                <img src={UpArrow} alt="" />
              )}
            </button>
          </div>
        )
      )}
                    {/* { droper && <DropMe dragLeave={dragLeave} dragOver={dragOver} handleDrop={handleDrop} />} */}
                    {open && <ListingModal buttonText={buttonText} setButtonText={setButtonText} setOpen={() => setOpen(false)} setEditLoader={setEditLoader} videoFiles={videoFiles} setVideoFiles={setVideoFiles} />}
        <ToastContainer closeOnClick={false} className={toastVisiblity !== true ? styles.toastContainerShow : styles.toastContainerNone} />
      </AppContext.Provider>
    </AuthContext.Provider>
  );
};
